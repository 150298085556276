<template>
  <v-container
    grid-list-xl>
    <v-layout
      fluid
      pt-0
      wrap>
      <v-flex xs12>

        <app-card
          :footer="true"
          :heading="`Customer: ${userData.name || ''}`"
          col-classes="xl12 lg12 md12 sm12 xs12"
          custom-classes="mb-0"
        >
          <v-tabs
            v-model="currentTab"
            grow>
            <v-tab href="#tab-passenger">Customer information</v-tab>
            <v-tab href="#tab-user">User information</v-tab>
            <v-tab
              href="#tab-notes"
              @change="loadNotes">Note about the customer</v-tab>
            <v-tab
              href="#tab-combined"
              @change="loadCombinedInvoices">Combined invoices</v-tab>
            <v-tab href="#tab-secretaries">Secretaries</v-tab>
            <v-tab href="#tab-payment">Payment</v-tab>
            <v-tab
              v-if="isEdriversPassenger"
              href="#tab-custom"
              @change="loadCustomInvoices">Custom invoices</v-tab>
            <v-tab
              v-if="isEdriversPassenger"
              href="#tab-discounts"
              @change="getDiscounts">Discounts</v-tab>
            <v-tabs-items v-model="currentTab">
              <v-tab-item value="tab-passenger">
                <div>
                  <rotate-square2 v-if="loading" />
                  <v-container
                    v-else
                    grid-list-xl>
                    <v-layout
                      row
                      wrap>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.firstName"
                          label="First name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.lastName"
                          label="Last name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.email"
                          label="Email"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.phone"
                          label="Phone"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.country"
                          label="Country"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.language"
                          label="Language"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-select
                          v-if="brandList.length"
                          :items="brandList"
                          :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                          v-model="userData.brands"
                          :item-text="(val) => { return val.name }"
                          clearable
                          item-value="id"
                          multiple
                          label="Brands"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        id="login"
                        xs6>
                        <v-overflow-btn
                          :disabled="!user.email"
                          :items="loginOptions"
                          label="Select login option..."
                          segmented
                          class="login-btn"
                          target="#login"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout
                      row
                      wrap>
                      <v-flex
                        xs6
                      >
                        <v-switch
                          v-model="userData.business"
                          label="Business"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-text-field
                          v-model="userData.companyName"
                          label="Company name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <div>
                          <span>Invoice address</span>
                          <v-radio-group
                            v-model="userData.invoiceType"
                            :class="'custom-radio-inline'"
                            row
                            hide-details
                            @change="blurSaving"
                          >
                            <v-radio
                              label="Private"
                              value="private"
                            />
                            <v-radio
                              label="Business"
                              value="business"
                            />
                          </v-radio-group>
                        </div>
                      </v-flex>
                      <v-flex
                        xs3
                      >
                        <v-switch
                          v-model="userData.useInvoiceEmailAsDefault"
                          label="Only use invoice email"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs3
                      >
                        <v-switch
                          v-model="userData.sepa"
                          label="SEPA"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-select
                          :items="invoicingTypeOptions"
                          v-model="userData.invoicingType"
                          item-value="value"
                          item-text="text"
                          label="Invoice period"
                          filled
                          hide-details
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-text-field
                          v-model="userData.invoiceEmail"
                          label="Invoice email"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-text-field
                          v-model="userData.secondEmail"
                          label="Second email"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <InputHotKeyWrapper>
                          <v-text-field
                            v-model="userData.travelKmTariff"
                            label="Travel km tariff"
                            type="number"
                            @blur="blurSaving"
                            @input="inputSaving"
                          />
                        </InputHotKeyWrapper>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-autocomplete
                          v-model="userData.defaultDriverType"
                          :items="getDriverTypesEdrivers()"
                          :item-text="(val) => { return val.title + ' id: ' + val.id }"
                          label="Default driver type (ED)"
                          item-value="id"
                          hide-details
                          clearable
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-textarea
                          v-model="userData.description"
                          label="Description"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-textarea
                          v-model="userData.addressBusiness"
                          label="Address business"
                          @blur="blurSaving"
                          @input="(e) => { inputSaving(); parseAddressBusiness(e) }"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-textarea
                          v-model="userData.addressPrivate"
                          label="Address private"
                          @blur="blurSaving"
                          @input="(e) => { inputSaving(); parseAddressPrivate(e) }"
                        />
                      </v-flex>
                      <!-- <v-flex
                        xs12
                        sm6>
                        <v-select
                          v-if="brandList && brandList.length"
                          :items="brandList"
                          :rules="[v => v && v.length>0 || 'Please select at least 1 brand']"
                          v-model="userData.brands"
                          :item-text="(val) => { return val.name }"
                          clearable
                          item-value="id"
                          multiple
                          label="Brands"
                        />
                      </v-flex> -->
                      <v-flex
                        xs12
                        sm6>
                        <v-text-field
                          v-model="userData.poNumber"
                          label="PO number"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <group-switch
                          :value="userData.insure"
                          :option-values="insuranceOptionValues"
                          label="Insure"
                          @onChange="() => { onInsuranceChange(); blurSaving() }" />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-select
                          :items="carPositionList"
                          v-model="userData.options.position"
                          label="Position in car"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-select
                          :items="driverDressList"
                          v-model="userData.options.driverDress"
                          label="Driver dress"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <group-switch
                          :value="userData.options.carryLuggage"
                          label="Carry luggage"
                          @onChange="() => { onCarryLuggageChange(); blurSaving() }" />

                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <group-switch
                          :value="userData.options.informalLanguage"
                          label="Informal language"
                          @onChange="() => { onCarryLuggageChange(); blurSaving() }" />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <group-switch
                          :value="userData.options.keepDoorsOpen"
                          label="Keep doors open"
                          @onChange="() => { onKeepDoorsOpenChange(); blurSaving() }" />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <group-switch
                          :value="userData.options.playMusic"
                          label="Play music"
                          @onChange="() => { onPlayMusicChange(); blurSaving() }" />
                      </v-flex>
                      <v-layout
                        v-if="carList && carList.length > 0"
                        column
                        wrap>
                        <div class="dark-blue-16 mb-2">Cars:</div>
                        <v-data-table
                          :headers="carsTableHeaders"
                          :items="carList"
                          hide-actions >
                          <template
                            slot="items"
                            slot-scope="props">
                            <td>
                              {{ props.item.id }}
                            </td>
                            <td>
                              {{ props.item.brand }}
                            </td>
                            <td>
                              {{ props.item.model }}
                            </td>
                            <td>
                              {{ props.item.licensePlate }}
                            </td>
                            <td>
                              {{ props.item.transmissionType }}
                            </td>
                            <!-- TODO Show delete btn if needed -->
                            <td>
                              <v-btn
                                :loading="carsTableButtonLoader"
                                color="warning"
                                class="mr-3"
                                @click="openEditDialogCar(props.item)"
                              >
                                Edit
                              </v-btn>
                            </td>
                            <td>
                              <v-btn
                                :loading="carsTableButtonLoader"
                                color="red"
                                class="mr-3"
                                @click="openDeleteDialogCar(props.item.id)"
                              >
                                Delete
                              </v-btn>
                            </td>
                          </template>
                        </v-data-table>
                        <v-flex
                          xs12
                        >
                          <v-btn
                            color="green"
                            class="add-car"
                            @click="openCreateDialogCar()">Add car</v-btn>
                        </v-flex>
                      </v-layout>
                      <div class="field-wrapper mt-5 w-100">
                        <v-layout
                          row
                          class="align-stretch"
                          wrap>
                          <v-flex
                            v-if="userData.loyaltyPoints"
                            xs6
                            sm3
                            lg2>
                            <InputHotKeyWrapper>
                              <v-text-field
                                v-model="userData.loyaltyPoints.driveme"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                label="DriveMe Loyalty"
                                type="number"
                                class="fs-14"
                                @blur="blurSaving"
                                @input="inputSaving"
                              />
                            </InputHotKeyWrapper>
                            <InputHotKeyWrapper>
                              <v-text-field
                                v-model="userData.loyaltyPoints.edrivers"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                label="Edrivers Loyalty"
                                type="number"
                                class="fs-14"
                                @blur="blurSaving"
                                @input="inputSaving"
                              />
                            </InputHotKeyWrapper>
                            <InputHotKeyWrapper>
                              <v-text-field
                                v-model="userData.loyaltyPoints.rentabob"
                                :max-length="TEXT_FIELD_MAX_LENGTH"
                                label="Rentabob Loyalty"
                                type="number"
                                class="fs-14"
                                @blur="blurSaving"
                                @input="inputSaving"
                              />
                            </InputHotKeyWrapper>
                          </v-flex>
                          <v-flex
                            v-if="userData.paymentConditions"
                            xs6
                            sm3
                            lg2>
                            <v-select
                              v-model="userData.paymentConditions.driveme.id"
                              :items="paymentConditions.filter(item => parseInt(item.brand.id) === 1)"
                              item-value="id"
                              item-text="title"
                              label="Driveme Payment Condition"
                              @change="blurSaving"
                            />
                            <v-select
                              v-model="userData.paymentConditions.edrivers.id"
                              :items="paymentConditions.filter(item => parseInt(item.brand.id) === 3)"
                              item-value="id"
                              item-text="title"
                              label="Edrivers Payment Condition"
                              @change="blurSaving"
                            />
                            <v-select
                              v-model="userData.paymentConditions.rentabob.id"
                              :items="paymentConditions.filter(item => parseInt(item.brand.id) === 2)"
                              item-value="id"
                              item-text="title"
                              label="Rentabob Payment Condition"
                              @change="blurSaving"
                            />
                          </v-flex>
                        </v-layout>
                      </div>
                      <v-flex
                        xs12>
                        <v-btn
                          color="primary"
                          @click="sendWelcome()">Send welcome e-mail</v-btn>
                      </v-flex>
                      <v-flex
                        xs6
                      >
                        <router-link
                          :to="`/dashboard/ride-overview?passenger=${$route.params.id}`"
                          @click.native="rideOverviewClick" >
                          Ride overview
                        </router-link>
                      </v-flex>
                      <v-flex
                        xs6
                      >
                        <v-switch
                          v-model="userData.payOnInvoice"
                          label="Pay on invoice"
                          @change="blurSaving"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-btn
                    color="primary"
                    class="mr-3 ml-4"
                    @click="saveFunction()"
                  >Save</v-btn>
                  <v-btn
                    color="error"
                    @click="$router.push('/tables/passengers')"
                  >Close</v-btn>
                </div>
              </v-tab-item>
              <v-tab-item value="tab-user">
                <v-form
                  v-if="user"
                  ref="userForm"
                  lazy-validation>
                  <v-container grid-list-xl>
                    <h4 class="my-4">User information</h4>
                    <v-form
                      v-if="user"
                      lazy-validation>
                      <v-layout
                        row
                        wrap>
                        <v-flex
                          v-if="roleList && user"
                          xs12
                          sm6>
                          <v-select
                            :items="Object.keys(roleList)"
                            :rules="[v => v.length>0 || 'Please select at least 1 user role']"
                            v-model="user.roles"
                            multiple
                            label="Roles"
                            @change="blurSaving"
                          />
                        </v-flex>
                        <v-flex
                          v-if="user"
                          xs12
                          sm4>
                          <v-switch
                            v-model="user.active"
                            label="Active"
                            @change="blurSaving"
                          />
                        </v-flex>
                        <v-flex
                          v-if="user"
                          xs12>
                          <v-text-field
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="user.plainPassword"
                            :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                            :type="showPasswordField ? 'text' : 'password'"
                            label="Plain password"
                            @click:append="showPasswordField = !showPasswordField"
                            @blur="blurSaving"
                            @input="inputSaving"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-btn
                            color="primary"
                            @click="saveFunctionUser()"
                          >Save</v-btn>
                          <v-btn
                            color="error"
                            @click="$router.push('/tables/passengers')"
                          >Close</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-container>
                </v-form>
              </v-tab-item>
              <v-tab-item value="tab-notes">
                <div
                  v-if="loadingNote"
                  class="loader-holder">
                  <rotate-square2 />
                </div>
                <v-container v-else>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Admin`s note</div>
                    <v-textarea
                      v-model="userData.adminNote"
                      label="Note about the customer"
                      hide-details
                      outline
                    />
                    <v-btn
                      color="pink"
                      dark
                      class="btn-add-note"
                      @click="saveNote()"
                    >Save</v-btn>
                  </div>
                  <div class="field-wrapper">
                    <div class="custom-label black--text">Driver`s note</div>

                    <v-layout class="align-items-center">
                      <v-flex>
                        <v-btn
                          fab
                          color="pink"
                          dark
                          @click="newNoteDialog = !newNoteDialog"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex v-if="driverNotes && driverNotes.length > 1">
                        <div class="sort-direction">
                          <v-select
                            :items="sortDirectionList"
                            v-model="sortDirection"
                            label="Sort by direction"
                            outline
                            hide-details
                            @change="loadNotes"
                          />
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      v-if="driverNotes && driverNotes.length > 0"
                      row
                      wrap>
                      <v-flex xs12>
                        <div class="driver-note-wrapper my-4">
                          <v-layout
                            row
                            wrap>
                            <v-flex
                              v-for="(note, key) in driverNotes"
                              :key="key"
                              md6>
                              <div class="custom-label black--text mb-1">Driver: {{ note.driver && note.driver.name ? note.driver.name : note.user.firstName + ' ' + note.user.lastName }}</div>
                              <div class="driver-note-holder">
                                <div class="note-header">
                                  <small class="black--text"><em>{{ momentDateTime(note.createdAt) }}</em></small>
                                  <div>
                                    <v-btn
                                      class="m-0"
                                      flat
                                      icon
                                      @click="openEditNoteDialog(note)"
                                    >
                                      <v-icon
                                        color="black"
                                        size="20">
                                        edit
                                      </v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="note"
                                      class="m-0"
                                      flat
                                      icon
                                      @click="openRemoveNoteDialog(note.id)"
                                    >
                                      <v-icon
                                        color="pink"
                                        size="20">
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                                <p class="my-0 black--text">{{ note.text }}</p>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div class="pagination-holder">
                          <v-pagination
                            v-model="driverNotePage"
                            :length="driverNoteTotalPage"
                            total-visible="7"
                            color="pink"
                            class="mb-4 mt-2"
                            @input="loadNotes"
                          />
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>

                  <v-dialog
                    v-model="noteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog">
                      <v-textarea
                        v-model="editNoteObject.text"
                        outline
                        rows="6"
                        class="custom-textarea mt-3"
                        placeholder="Leave your message here"
                      />
                      <v-btn
                        class="btn-submit white--text"
                        color="pink"
                        block
                        @click="editNote()"
                      >
                        Update note
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </div>
                  </v-dialog>
                  <v-dialog
                    v-model="newNoteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog">
                      <v-textarea
                        v-model="newNoteObject.text"
                        outline
                        rows="6"
                        class="custom-textarea mt-3"
                        placeholder="Leave your message here"
                      />
                      <v-btn
                        class="btn-submit white--text"
                        color="pink"
                        block
                        @click="addNote"
                      >
                        Save
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </div>
                  </v-dialog>
                  <v-dialog
                    v-model="removeNoteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog py-4">
                      <h2 class="text-center mt-0 mb-4">Weet je zeker dat?</h2>

                      <div class="note-actions">
                        <v-btn
                          color="green"
                          text
                          @click="cancelRemoveNoteDialog"
                        >
                          Annuleren
                        </v-btn>

                        <v-btn
                          color="red"
                          text
                          @click="deleteNote"
                        >
                          Bevestigen
                        </v-btn>
                      </div>
                    </div>
                  </v-dialog>
                </v-container>
              </v-tab-item>
              <v-tab-item value="tab-combined">
                <div>
                  <v-layout>
                    <v-flex xs5/>
                    <v-flex xs3>
                      <group-switch
                        :value="draftFilter"
                        :option-values="draftOptionValues"
                        label="Draft"
                        @onChange="onDraftChange" />
                    </v-flex>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="isSentInvoice"
                        :label="`Sent`"
                        hide-details
                        @change="loadCombinedInvoices"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="isSyncInvoice"
                        :label="`Synced`"
                        hide-details
                        @change="loadCombinedInvoices"
                      />
                    </v-flex>
                  </v-layout>
                  <v-data-table
                    :headers="combinedInvoicesHeaders"
                    :items="combinedInvoices"
                    :loading="loading"
                    class="elevation-1"
                    hide-actions
                    hide-default-header
                    hide-default-footer
                  >
                    <template v-slot:items="props">
                      <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          <div>
                            <v-icon
                              v-if="props.item.sentAt"
                              color="green">mail</v-icon>
                            <v-icon
                              v-else
                              color="red">mail_outline</v-icon>
                          </div>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          {{ props.item.number }}
                        </a>
                      </td>
                      <!-- <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          {{ props.item.brand.name }}
                        </a>
                      </td> -->
                      <td>
                        <v-tooltip
                          v-if="!props.item.draft && props.item.closedAt"
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              class="c-pointer"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              close
                            </v-icon>
                          </template>
                          <p>closed at:</p>
                          <span>{{ momentDateTime(props.item.closedAt) }}</span>
                        </v-tooltip>
                        <v-icon
                          v-else
                          color="success"
                          class="c-pointer"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          check
                        </v-icon>
                      </td>
                      <td class="text-center">
                        {{ momentDateTime(props.item.invoicedAt) }}
                        <v-dialog
                          v-if="props.item.draft"
                          v-model="props.item.invoiceEditDialog"
                          width="500"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              color="primary"
                              dark
                              small
                              v-on="on"
                            >
                              <v-icon>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title class="text-h5 lighten-2">
                              Invoiced At edit
                            </v-card-title>

                            <v-card-text>
                              <v-text-field
                                v-model="props.item.invoicedAt"
                                label="Invoiced at"
                                type="datetime-local"
                              />
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer/>
                              <v-btn
                                color="primary"
                                text
                                @click="invoicedAtEdit(props.item)"
                              >
                                save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </td>
                      <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          {{ momentDateTime(props.item.expiredAt) }}
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          {{ props.item.paidAt }}
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          :href="`/tables/combined-invoices/${props.item.id}`"
                          target="_blank"
                          class="row-link">
                          {{ props.item.sentAt }}
                        </a>
                      </td>
                      <td>
                        <v-btn
                          v-if="props.item.pdfGenerationStatus === 'done' && props.item.file"
                          :href="props.item.file.url"
                          color="primary"
                          dark
                          download
                          target="_blank"
                        >
                          <v-icon>download</v-icon>
                        </v-btn>
                        <span v-else-if="props.item.pdfGenerationStatus === 'scheduled'">PDF generation scheduled</span>
                        <span v-else-if="props.item.pdfGenerationStatus === 'failed'">PDF generation failed</span>

                        <v-btn
                          color="red"
                          dark
                          @click="onDeleteCombinedInvoiceClick(props.item.id)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>

                        <div>
                          <send-invoice-button
                            :invoice-id="props.item.id"
                            :after-action="loadCombinedInvoices"
                            button-text="Send"
                            is-combined />
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                  <v-btn
                    color="primary"
                    class="mr-3 mt-3"
                    @click="openGenereteInvoiceDialog()"
                  >
                    Generate invoice
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item value="tab-secretaries">
                <div>
                  <crud-table
                    v-if="user"
                    ref="crudTableSecretary"
                    :list-items="secretariesHeaders"
                    :is-editable="false"
                    :is-disabled-deleted-filter="true"
                    :is-restorable="false"
                    :is-deleteble="true"
                    :show-brands-filter="false"
                    :show-search-filter="false"
                    :custom-template="true"
                    :query-string="`&client=${user.id}`"
                    heading="Secretaries"
                    endpoint="secretaries"
                    row-click-path="/tables/secretaries"
                    @create="createSecretary"
                    @delete="deleteSec"
                  >
                    <template v-slot:items="props">
                      <td>
                        {{ props.item.id }}
                      </td>
                      <td>
                        <a
                          :href="`/tables/users/${props.item.master.id}`">
                          <span>{{ props.item.master.name }}</span>
                        </a>
                        <br>
                        <b>email: </b><span>{{ props.item.master.email }}</span>
                      </td>
                      <td>
                        <a
                          :href="`/tables/users/${props.item.client.id}`">
                          <span>{{ props.item.client.name }}</span>
                        </a>
                        <br>
                        <b>email: </b><span>{{ props.item.client.email }}</span>
                      </td>
                      <td>
                        {{ props.item.brand.name }}
                      </td>
                      <td>
                        {{ props.item.status }}
                      </td>
                      <td>
                        {{ props.item.invitedAt }}
                      </td>
                    </template>
                  </crud-table>

                  <edit-dialog
                    :show.sync="editDialogSecretary.display"
                    :max-width="'600'"
                    @dialogConfirm="editDialogSecretary.saveFunction">
                    <template>
                      <v-container grid-list-xl>
                        <v-layout
                          row
                          wrap>
                          <v-flex
                            xs12
                            sm6>
                            <v-select
                              v-if="userData && userData.brands && userData.brands.length"
                              :items="userData.brands"
                              v-model="editDialogSecretary.data.brand"
                              :item-text="(val) => { return val.name }"
                              item-value="id"
                              label="Brand"
                            />
                          </v-flex>
                          <v-flex
                            v-if="editDialogSecretary.type != 'edit'"
                            xs12
                            sm6>
                            <users-input
                              :disabled="!editDialogSecretary.data.brand"
                              v-model="editDialogSecretary.data.master"
                              :brand-list="[editDialogSecretary.data.brand]"
                              :query-params="{ params: { role: 'ROLE_PASSENGER' } }"
                              label="Secretary"
                              @changeUser="(inputUser) => { editDialogSecretary.data.master = inputUser.id }" />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6>
                            <v-select
                              :items="['active', 'inactive']"
                              v-model="editDialogSecretary.data.status"
                              label="Status"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </edit-dialog>
                </div>
              </v-tab-item>
              <v-tab-item value="tab-payment">
                <div class="mt-5">
                  <div
                    xs12
                    sm6
                    md4>
                    <div class="custom-label black--text size-18">Payment</div>

                    <v-select
                      v-if="rideList && rideList.length"
                      :items="rideList"
                      v-model="paymentSelectedRide"
                      :item-text="(val) => { return val && val.id }"
                      clearable
                      item-value="id"
                      label="Select a ride for payment"
                    />
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="paymentAmount"
                        type="number"
                        label="Amount"
                        class="mb-4"
                      />
                    </InputHotKeyWrapper>
                    <v-text-field
                      v-model="paymentDescription"
                      label="Description"
                      class="mb-4"
                    />
                    <user-payment
                      v-if="!fetchPaymentDataLoader && userData && userData.user"
                      :active-pay-on-invoce="activePayOnInvoice"
                      :active-mandate="paymentSelectedMandate"
                      :can-add-new-payment-method="userData.payOnInvoice"
                      :pay-invoice-enabled="payInvoiceEnabled"
                      :user-id="userData.user.id"
                      :can-delete="false"
                      @onSelectMandate="onSelectMandate" />
                    <div v-if="paymentSelectedMandate">
                      <v-btn
                        :disabled="createPaymentLoader || !paymentAmount || !paymentDescription"
                        color="green"
                        class="mr-3"
                        @click="pay"
                      >Pay</v-btn>
                    </div>
                  </div>
                  <div
                    v-if="ridePayments && ridePayments.length > 0"
                    xs12>
                    <v-data-table
                      :headers="paymentsTableHeaders"
                      :items="ridePayments"
                      hide-actions >
                      <template
                        slot="items"
                        slot-scope="props">
                        <td>
                          {{ props.item.id }}
                        </td>
                        <td>
                          {{ props.item.amount }}
                        </td>
                        <td>
                          {{ props.item.method }}
                        </td>
                        <td>
                          {{ props.item.status }}
                        </td>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item
                v-if="isEdriversPassenger"
                value="tab-custom">
                <div>
                  <v-layout>
                    <v-flex xs8/>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="isSentInvoice"
                        :label="`Sent`"
                        hide-details
                        @change="loadCustomInvoices"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="isSyncInvoice"
                        :label="`Synced`"
                        hide-details
                        @change="loadCustomInvoices"
                      />
                    </v-flex>
                  </v-layout>
                  <v-data-table
                    :headers="customInvoicesHeaders"
                    :items="customInvoices"
                    :loading="loading"
                    class="elevation-1"
                    hide-actions
                    hide-default-header
                    hide-default-footer
                  >
                    <template v-slot:items="props">
                      <td class="text-center">
                        {{ props.item.brand.name }}
                      </td>
                      <td class="text-center">
                        <router-link
                          :to="`/tables/passengers/${props.item.passenger.id}`"
                        >
                          {{ props.item.passenger.name }}
                        </router-link>
                      </td>
                      <td class="text-center">
                        <router-link
                          :to="`/tables/drivers/${props.item.driver.id}`"
                        >
                          {{ props.item.driver.name }}
                        </router-link>
                      </td>
                      <td class="py-2">
                        <div
                          v-for="(line, index) in props.item.lines"
                          :key="index">
                          <div :class="`${props.item.lines && index < props.item.lines.length - 1 ? 'line-border' : ''}`">
                            <b>Price:</b> {{ line.price }}<br> <b>Amount:</b> {{ line.amount }}<br> <b>VAT:</b> {{ line.VAT }}<br> <b>Name:</b> {{ line.name }}
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ props.item.pdfGenerationStatus }}
                      </td>
                      <td class="text-center">
                        {{ props.item.number }}
                      </td>
                      <td class="text-center">
                        {{ gFunc.momentDateTime(props.item.invoicedAt) }}
                      </td>
                      <td class="text-center">
                        <div v-if="props.item.sentAt">
                          {{ gFunc.momentDateTime(props.item.sentAt) }}
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          v-if="props.item.file && props.item.file.url"
                          :href="props.item.file.url"
                          target="_blank"
                          @click.stop="(event) => event.stopPropagation()">{{ props.item.file.name }}</a>
                      </td>
                      <td class="text-center">
                        <payment-invoice
                          :disabled="!props.item.file"
                          :passenger="props.item.passenger"
                          :invoice-id="props.item.id"
                          invoice-type="custom"
                          @onPay="loadCustomInvoices" />

                        <v-btn
                          :disabled="sendInvoiceLoader || !props.item.file"
                          :loading="sendInvoiceLoader"
                          color="secondary"
                          @click="() => { sendCustomInvoice(props.item.id) }">
                          Send
                        </v-btn>

                        <v-btn
                          color="red"
                          dark
                          @click="onDeleteCustomInvoiceClick(props.item.id)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </v-data-table>
                  <div class="mt-3">
                    <add-custom-invoice
                      v-if="userData && userData.id"
                      :passenger-prop-id="userData.id"
                      :on-create="loadCustomInvoices"/>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item
                v-if="isEdriversPassenger"
                value="tab-discounts">
                <div>
                  <v-flex
                    xs12
                    class="row-layout justify-end">
                    <v-btn
                      color="primary"
                      dark
                      @click="createDiscount()"
                    >
                      Create
                    </v-btn>
                  </v-flex>
                  <v-data-table
                    :headers="discountHeaders"
                    :items="discounts"
                    class="elevation-1"
                    hide-actions
                    hide-default-header
                    hide-default-footer
                  >
                    <template v-slot:items="props">
                      <td class="text-center">
                        {{ props.item.id }}
                      </td>
                      <td class="text-center">
                        {{ props.item.driverType.title }}
                      </td>
                      <td class="text-center">
                        {{ props.item.amount }}
                      </td>
                      <td class="text-center">
                        {{ props.item.type }}
                      </td>
                      <td>
                        <v-btn
                          color="red"
                          dark
                          @click="deleteDiscount(props.item.driverType.id)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          dark
                          @click="editDiscount(props.item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </app-card>

      </v-flex>
    </v-layout>
    <delete-confirmation-dialog
      ref="deleteCarConfrimationDialog"
      heading="Are You Sure?"
      @onConfirm="deleteDialogConfirmationCar"
    />
    <edit-dialog
      :loader="carsTableButtonLoader"
      :show.sync="editDialogCar.display"
      @dialogConfirm="editDialogCar.saveFunction"
      @dialogClose="editDialogCar.closeFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialogCar.data.brand"
                type="text"
                label="Brand"
                class="mb-4"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialogCar.data.model"
                type="text"
                label="Model"
                class="mb-4"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialogCar.data.licensePlate"
                type="text"
                label="License plate"
                class="mb-4"
              />

            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="['manual', 'automatic']"
                v-model="editDialogCar.data.transmissionType"
                label="Transmission type"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
    <edit-dialog
      :loader="generateInvoiceLoader"
      :show.sync="generateInvoiceDialog.display"
      max-width="1000px"
      fullscreen
      @dialogConfirm="generateInvoiceDialog.saveFunction"
      @dialogClose="generateInvoiceDialog.closeFunction">
      <template>
        <v-flex
          xs3>
          <v-select
            v-if="brandList && brandList.length"
            :items="userData.brands"
            :rules="[v => v && v.length>0 || 'Please select brand']"
            v-model="generateInvoiceDialog.data.brand"
            :item-text="(val) => { return val.name }"
            clearable
            item-value="id"
            label="Brand"
          />
        </v-flex>
        <v-data-table
          :headers="generateInvoicesRidesHeader"
          :items="generateInvoicesRides"
          :loading="generateInvoiceLoader"
          v-model="generateInvoicesRidesSelected"
          class="elevation-1"
          hide-actions
          select-all
          show-select
          hide-default-header
          hide-default-footer
        >
          <template v-slot:items="props">
            <td>
              <v-checkbox
                v-if="props.item.status.sequence === rideStatus.declaration_approved.sequence"
                v-model="props.selected"
                primary
                hide-details
              />
            </td>
            <td class="text-center">
              <router-link
                :to="`/dashboard/ride-overview?&rideId=${props.item.id}`"
                target="_blank" >
                {{ props.item.id }}
              </router-link>
            </td>
            <td class="text-center">
              {{ props.item.createdAt }}
            </td>
            <td class="text-center">
              {{ gFunc.momentDate(props.item.startAt) }}
            </td>
            <td class="text-center">
              {{ gFunc.momentTime(props.item.startAt) }}
            </td>
            <td class="text-center">
              <router-link
                v-if="props.item.driver"
                :to="`/tables/drivers/${props.item.driver.id}`"
              >
                <span class="mr-1">
                  {{ props.item.driver.name }}
                </span>
              </router-link>
              <span v-else>...</span>
            </td>
          </template>
        </v-data-table>
      </template>
    </edit-dialog>
    <edit-dialog
      :show.sync="createDiscountDialog.display"
      max-width="500"
      @dialogConfirm="createDiscountDialog.saveFunction">
      <template>
        <v-form
          ref="discountForm"
          v-model="validDiscount"
          lazy-validation>
          <v-container grid-list-xl>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6>
                <InputHotKeyWrapper>
                  <v-text-field
                    v-model="createDiscountDialog.data.amount"
                    :rules="[v => Boolean(v) || 'Please provide an amount']"
                    label="Amount"
                    type="number"
                  />
                </InputHotKeyWrapper>
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-autocomplete
                  v-model="createDiscountDialog.data.driverTypeId"
                  :rules="[v => Boolean(v) || 'Please provide a Driver type']"
                  :items="typesListForPassenger()"
                  :item-text="(val) => { return val.title + ' id: ' + val.id }"
                  label="Driver type"
                  item-value="id"
                  clearable
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-select
                  v-model="createDiscountDialog.data.type"
                  :rules="[v => Boolean(v) || 'Please provide a discount type']"
                  :items="Object.values(DISCOUNT_TYPE)"
                  :item-text="(val) => { return val }"
                  label="Discount type"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </edit-dialog>
    <edit-dialog
      :show.sync="editDiscountDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDiscountDialog.saveFunction">
      <template>
        <h2 class="ml-3">Edit discount</h2>
        <v-form
          ref="discountForm"
          v-model="validDiscount"
          lazy-validation>
          <v-container grid-list-xl>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6>
                <InputHotKeyWrapper>
                  <v-text-field
                    v-model="editDiscountDialog.data.amount"
                    :rules="[v => Boolean(v) || 'Please provide an amount']"
                    label="Amount"
                    type="number"
                  />
                </InputHotKeyWrapper>
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-select
                  v-model="editDiscountDialog.data.type"
                  :rules="[v => Boolean(v) || 'Please provide a discount type']"
                  :items="Object.values(DISCOUNT_TYPE)"
                  :item-text="(val) => { return val }"
                  label="Discount type"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </edit-dialog>
    <page-saving-status :status="savingStatus" />
  </v-container>
</template>

<script>
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import UsersInput from '../../../../modules/components/UsersInput.vue'
import AddCustomInvoice from '../../../../modules/components/AddCustomInvoice.vue'
import PaymentInvoice from '../../../../modules/components/PaymentInvoice.vue'
import Request from '../../../../helpers/request'
import Brands from '../../../../helpers/brands'
import Roles from '../../../../helpers/roles'
import Payment from '../../../../helpers/payment'
import CombinedInvoices from '../../../../helpers/combined-invoices'
import StatusList from '../../../../mixins/StatusList.vue'
import GroupSwitch from 'Components/GroupSwitch'
import UserPayment from 'Components/Payment'
import SendInvoiceButton from 'Components/Buttons/SendInvoiceButton.vue'
import Secretary from '../../../../helpers/secretary'
import { BRAND, TEXT_FIELD_MAX_LENGTH, DISCOUNT_TYPE, SAVING_STATUS } from 'Constants/common'
import { PASSENGER_CAR_POSITIONS, DRIVER_DRESSES, INSURE_PASSENGER_ASK, INSURE_PASSENGER_ALWAYS, INSURE_PASSENGER_NEVER } from '../../../../constants/common'
import AppConfig from 'Constants/AppConfig'
import moment from 'moment-timezone'
import lodash from 'lodash'
import PageSavingStatus from 'Components/PageSavingStatus/PageSavingStatus'

const combinedInvoicesHeaders = [
  { text: '', sortable: false },
  { text: 'Number', align: 'center', sortable: false },
  // { text: 'Brand', align: 'center', sortable: false },
  { text: 'Draft', align: 'center', sortable: false },
  { text: 'Invoiced at', align: 'center', sortable: false },
  { text: 'Expired at', align: 'center', sortable: false },
  { text: 'Paid at', align: 'center', sortable: false },
  { text: 'Sent at', align: 'center', sortable: false },
  { text: ' ', align: 'center', sortable: false }
]
const customInvoicesHeaders = [
  { text: 'Brand', align: 'center', sortable: false },
  { text: 'Customer', align: 'center', sortable: false },
  { text: 'Driver', align: 'center', sortable: false },
  { text: 'Lines', align: 'left', sortable: false },
  { text: 'Status', align: 'center', sortable: false },
  { text: 'Invoice number', align: 'center', sortable: false },
  { text: 'Invoiced At', align: 'center', sortable: false },
  { text: 'Sent At', align: 'center', sortable: false },
  { text: 'PDF', align: 'center', sortable: false },
  { text: ' ', align: 'center', sortable: false }
]

const generateInvoicesRidesHeader = [
  { text: 'ID', align: 'center', sortable: false },
  { text: 'Created at', align: 'center', sortable: false },
  { text: 'Date', align: 'center', sortable: false },
  { text: 'Time', align: 'center', sortable: false },
  { text: 'Driver', align: 'center', sortable: false }
]

const discountHeaders = [
  { text: 'ID', align: 'center', sortable: false },
  { text: 'Driver type', align: 'center', sortable: false },
  { text: 'Amount', align: 'center', sortable: false },
  { text: 'Type', align: 'center', sortable: false },
  { text: '', align: 'center', sortable: false }
]

const secretariesHeaders = [
  { text: 'ID', value: 'id' },
  { text: 'Secretary', value: 'master' },
  { text: 'Customer', value: 'client' },
  { text: 'Brand', value: 'brand', textValue: 'name', sortable: false },
  { text: 'Status', value: 'status' },
  { text: 'Invited at', value: 'invitedAt' },
  { text: '', value: null }
]

export default {
  components: { CrudTable, GroupSwitch, UserPayment, SendInvoiceButton, AddCustomInvoice, PaymentInvoice, UsersInput, PageSavingStatus },
  mixins: [Request, Brands, Payment, StatusList, CombinedInvoices, Roles, Secretary],
  data () {
    return {
      savingStatus: SAVING_STATUS.default,
      moment,
      DISCOUNT_TYPE,
      validDiscount: true,
      combinedInvoices: [],
      customInvoices: [],
      generateInvoicesRidesSelected: [],
      combinedInvoicesHeaders,
      customInvoicesHeaders,
      generateInvoicesRides: [],
      generateInvoicesRidesHeader,
      discountHeaders,
      secretariesHeaders,
      paymentSelectedRide: null,
      paymentSelectedMandate: null,
      activePayOnInvoice: false,
      payInvoiceEnabled: false,
      generateInvoiceLoader: false,
      paymentAmount: null,
      paymentDescription: null,
      createPaymentLoader: false,
      fetchPaymentDataLoader: false,
      ridePayments: [],
      carList: [],
      usersList: [],
      rideList: [],
      brandList: [],
      roleList: [],
      userData: {},
      initUserData: {},
      user: null,
      initUser: null,
      loading: true,
      loadingNote: false,
      data: [],
      insuranceOptionValues: {
        on: { text: 'ON', value: INSURE_PASSENGER_ALWAYS },
        middle: { text: 'ASK', value: INSURE_PASSENGER_ASK },
        off: { text: 'OFF', value: INSURE_PASSENGER_NEVER }
      },
      editDialog: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      generateInvoiceDialog: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      createDiscountDialog: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      editDiscountDialog: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      files: {},
      carsTableHeaders: [ { text: 'id', sortable: false }, { text: 'brand', sortable: false }, { text: 'model', sortable: false }, { text: 'licensePlate', sortable: false }, { text: 'transmissionType', sortable: false }, { text: '', sortable: false }, { text: '', sortable: false } ],
      paymentsTableHeaders: [ { text: 'id', sortable: false }, { text: 'amount', sortable: false }, { text: 'method', sortable: false }, { text: 'status', sortable: false } ],
      carsTableButtonLoader: false,
      carPositionList: PASSENGER_CAR_POSITIONS,
      driverDressList: DRIVER_DRESSES,
      imageData: null,
      deleteDialogConfirmationCar: () => {},
      editDialogCar: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      editDialogSecretary: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      driverNotePage: 1,
      driverNoteTotalPage: 0,
      driverNotePerPage: 20,
      driverNotes: [],
      driverTypes: [],
      discounts: [],
      sortDirectionList: ['asc', 'desc'],
      sortDirection: 'desc',
      noteDialog: false,
      newNoteDialog: false,
      removeNoteDialog: false,
      removeNoteId: null,
      editNoteObject: {},
      newNoteObject: {},
      oldAddressBusiness: '',
      oldAddressPrivate: '',
      invoicingTypeOptions: [
        { text: 'each ride', value: 'per_ride' },
        { text: 'weekly', value: 'weekly' },
        { text: 'monthly', value: 'monthly' }
      ],
      sendInvoiceLoader: false,
      isSentInvoice: false,
      isSyncInvoice: false,
      currentTab: 'tab-passenger',
      showPasswordField: false,
      TEXT_FIELD_MAX_LENGTH,
      draftFilter: null,
      draftOptionValues: {
        on: { text: 'ON', value: true },
        middle: { text: 'ALL', value: null },
        off: { text: 'OFF', value: false }
      },
      paymentConditions: []
    }
  },
  computed: {
    isEdriversPassenger () {
      if (this.userData && this.userData.brands) {
        return this.userData.brands.some(item => item.name === BRAND.EDRIVERS)
      }
      return false
    }
  },
  watch: {
    'generateInvoiceDialog.data.brand': {
      async handler (val) {
        try {
          const generateInvoicesRides = await this.fetchPassengerRides({ hasInvoiceCombined: false, status: `${this.rideStatus.declared.alias},${this.rideStatus.declaration_approved.alias},${this.rideStatus.driver_invoiced.alias}`, brands: val })

          this.generateInvoicesRides = generateInvoicesRides
          this.generateInvoicesRidesSelected = generateInvoicesRides
        } catch (error) {

        }
      },
      deep: true

    },
    paymentSelectedRide: {
      async handler (val) {
        if (val) {
          try {
            this.fetchPaymentDataLoader = true

            const [ride, ridePayments] = await Promise.all([this.fetchRide(val), this.getPayments({ params: { ride: val, user: this.user.id } })])

            this.ridePayments = ridePayments
            this.paymentSelectedMandate = ride.mandate ? ride.mandate.id : null
            this.activePayOnInvoice = ride.payOnInvoice
            this.payInvoiceEnabled = ride.passenger.payOnInvoice
            this.paymentAmount = ride.price ? ride.price.total : null
            this.fetchPaymentDataLoader = false
          } catch (error) {
            this.fetchPaymentDataLoader = true
          }
        } else {
          this.fetchPaymentDataLoader = true

          const [ridePayments] = await Promise.all([this.getPayments({ params: { ride: val, user: this.user.id } })])

          this.ridePayments = ridePayments
          this.fetchPaymentDataLoader = false
        }
      },
      deep: true
    },
    user: {
      handler (user) {
        const filteredRoles = ['ROLE_PASSENGER', 'ROLE_DRIVER']

        let loginOptions = []

        user.brands.forEach(brand => {
          user.roles.filter(role => filteredRoles.includes(role)).forEach(role => {
            loginOptions.push({
              text: role === 'ROLE_PASSENGER' ? `Login as customer (${brand.name})` : `Login as driver (${brand.name})`,
              callback: () => {
                window.open(`${AppConfig.BASE_URL[brand.name][AppConfig.env].replace('rentabob', 'driveme').replace('edrivers', 'driveme')}/one-time-login/user/${user.id}/${brand.id}/${role === 'ROLE_PASSENGER' ? 'passenger' : 'driver'}`, '_blank')
              }
            })
          })
        })

        this.loginOptions = loginOptions
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.requestData()
    document.title = this.userData.name || 'Customer'

    try {
      const [brandList, roleList, carList, rideList, payments, discounts, driverTypes, paymentConditions] = await Promise.all([this.fetchBrands(), this.fetchRoles(), this.fetchCars(), this.fetchPassengerRides(), this.getPayments({ params: { user: this.user.id } }), this.fetchDiscounts(), this.fetchDriverTypes(), this.fetchPaymentConditions()])

      this.ridePayments = payments
      this.brandList = brandList
      this.carList = carList
      this.rideList = rideList
      this.discounts = discounts
      this.driverTypes = driverTypes
      this.roleList = roleList
      this.paymentConditions = paymentConditions
    } catch (e) {
      throw new Error(e)
    }

    if (this.$route.query.tab) {
      this.currentTab = `tab-${this.$route.query.tab}`

      if (this.$route.query.tab === 'combined') {
        this.loadCombinedInvoices()
        this.openGenereteInvoiceDialog()
      }

      let updatedRouteQuery = { ...this.$route.query }
      delete updatedRouteQuery.tab
      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)
    }
  },
  methods: {
    inputSaving () {
      if (!lodash.isEqual(this.user, this.initUser) || !lodash.isEqual(this.userData, this.initUserData)) {
        this.savingStatus = SAVING_STATUS.wait
      } else {
        this.savingStatus = SAVING_STATUS.default
      }
    },
    async blurSaving () {
      if (!lodash.isEqual(this.user, this.initUser) || !lodash.isEqual(this.userData, this.initUserData)) {
        try {
          await this.saveFunction()
          this.savingStatus = SAVING_STATUS.saved
        } catch (error) {
          this.savingStatus = SAVING_STATUS.error
        }
      }
    },
    onDraftChange (value) {
      this.draftFilter = value
      this.loadCombinedInvoices()
    },
    getDriverTypesEdrivers () {
      if (this.driverTypes && this.driverTypes.length) {
        return this.driverTypes.filter(item => item.brand.name === BRAND.EDRIVERS)
      }
    },
    saveFunctionUser () {
      let body = {
        data: {
          'active': this.user.active,
          'roles': this.user.roles.join(','),
          'plainPassword': this.user.plainPassword
        }
      }

      this.request('PATCH', `/users/${this.user.id}`, body, () => {
        this.showPasswordField = false
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Gebruiker is gewijzigd'
        })
      })
    },
    createSecretary () {
      this.editDialogSecretary.display = true
      this.editDialogSecretary.data = { }
      this.editDialogSecretary.type = 'create'

      this.editDialogSecretary.saveFunction = async () => {
        try {
          let body = {
            data: {
              'master': this.editDialogSecretary.data.master,
              'client': this.user.id,
              'status': this.editDialogSecretary.data.status,
              'brand': this.editDialogSecretary.data.brand
            }
          }

          await this.request('POST', `/secretaries`, body)

          this.editDialogSecretary.display = false
          this.$refs.crudTableSecretary.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Secretary has been created'
          })
        } catch (error) {

        }
      }
    },
    async deleteSec (id) {
      try {
        await this.deleteSecretary(id)

        this.$refs.crudTableSecretary.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Secretary has been deleted'
        })
      } catch (error) {

      }
    },
    async sendCustomInvoice (invoiceId) {
      this.sendInvoiceLoader = true

      try {
        await this.request('POST', `/custom-invoices/${invoiceId}/send`, {})
        this.sendInvoiceLoader = false
        this.loadCustomInvoices()
      } catch (error) {
        this.sendInvoiceLoader = false
      }
    },
    rideOverviewClick () {
      this.$store.dispatch('removeAdminRideOverviewFilters')
    },
    invoicedAtEdit (invoice) {
      const body = { data: { invoicedAt: invoice.invoicedAt, rides: invoice.rides.map(item => item.id) } }
      this.request('PATCH', `/combined-invoices/${invoice.id}`, body, ({ data }) => {
        invoice.invoiceEditDialog = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'invoicedAt has been updated'
        })
      })
    },
    async getCustomInvoices (params = {}) {
      return this.request('GET', `/custom-invoices`, { params })
    },
    typesListForPassenger (isAll = false) {
      if (this.driverTypes && this.driverTypes.length && this.userData && this.userData.brands && this.userData.brands.length) {
        if (isAll) {
          return this.driverTypes.filter(item => this.userData.brands.map(brand => brand.name).includes(item.brand.name))
        }
        return this.driverTypes.filter(item => this.userData.brands.map(brand => brand.name).includes(item.brand.name) && !this.discounts.map(disc => disc.driverType.id).includes(item.id))
      }
      return []
    },
    editDiscount (data) {
      this.editDiscountDialog.display = true
      this.editDiscountDialog.type = 'edit'
      this.editDiscountDialog.data = this.gFunc.deepCopy(data)

      this.editDiscountDialog.saveFunction = () => {
        if (this.$refs.discountForm.validate()) {
          let body = {
            data: {
              'amount': this.editDiscountDialog.data.amount,
              'type': this.editDiscountDialog.data.type
            }
          }

          this.request('PATCH', `/passengers/${this.$route.params.id}/discount/driver-type/${this.editDiscountDialog.data.driverType.id}`, body, ({ data }) => {
            this.editDiscountDialog.display = false
            this.getDiscounts()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Discount has been updated'
            })
          })
        }
      }
    },
    createDiscount () {
      this.createDiscountDialog.display = true
      this.createDiscountDialog.type = 'create'
      this.createDiscountDialog.data = {}

      this.createDiscountDialog.saveFunction = () => {
        if (this.$refs.discountForm.validate()) {
          let body = {
            data: {
              'amount': this.createDiscountDialog.data.amount,
              'type': this.createDiscountDialog.data.type
            }
          }

          this.request('POST', `/passengers/${this.$route.params.id}/discount/driver-type/${this.createDiscountDialog.data.driverTypeId}`, body, ({ data }) => {
            this.createDiscountDialog.display = false
            this.getDiscounts()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Discount has been created'
            })
          })
        }
      }
    },
    parseAddress (value) {
      return value ? value.replaceAll(', ', '\n').replaceAll(',', '\n') : value
    },
    unParseAddress (value) {
      return value ? value.replaceAll('\n', ', ') : value
    },
    parseAddressBusiness (value) {
      this.userData.addressBusiness = this.parseAddress(value)
    },
    parseAddressPrivate (value) {
      this.userData.addressPrivate = this.parseAddress(value)
    },
    onCarryLuggageChange (value) {
      this.userData.options.carryLuggage = value
    },
    onInformalLanguageChange (value) {
      this.userData.options.informalLanguage = value
    },
    onKeepDoorsOpenChange (value) {
      this.userData.options.keepDoorsOpen = value
    },
    onInsuranceChange (value) {
      this.userData.insure = value
    },
    onPlayMusicChange (value) {
      this.userData.options.playMusic = value
    },
    async onDeleteCombinedInvoiceClick (id) {
      try {
        await this.deleteCombinedInvoice(id)
        await this.loadCombinedInvoices()
      } catch (error) {

      }
    },
    async onDeleteCustomInvoiceClick (id) {
      try {
        await this.deleteCustomInvoice(id)
        await this.loadCustomInvoices()
      } catch (error) {

      }
    },
    async deleteCustomInvoice (id) {
      try {
        await this.request('DELETE', `/custom-invoices/${id}`)
      } catch (error) {

      }
    },
    async deleteDiscount (id) {
      try {
        await this.request('DELETE', `/passengers/${this.$route.params.id}/discount/driver-type/${id}`)
        await this.getDiscounts()
      } catch (error) {

      }
    },
    async openGenereteInvoiceDialog () {
      this.generateInvoiceDialog = {
        data: {},
        display: true,
        dialogClose: () => {},
        type: 'create'
      }

      if (this.userData.brands.length === 1) {
        this.generateInvoiceDialog.data.brand = this.userData.brands[0].id
      }

      this.generateInvoiceDialog.saveFunction = async () => {
        try {
          this.generateInvoiceLoader = true

          const body = {
            brand: this.generateInvoiceDialog.data.brand,
            passenger: this.$route.params.id,
            rides: this.generateInvoicesRidesSelected.filter(ride => ride.status.sequence === this.rideStatus.declaration_approved.sequence).map(ride => ride.id)
          }

          await this.createCombinedInvoice(body)
          await this.loadCombinedInvoices()

          this.generateInvoiceLoader = false
          this.generateInvoiceDialog.display = false

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoices are created'
          })
        } catch (error) {
          this.generateInvoiceLoader = false
        }
      }
    },
    openCreateDialogCar () {
      this.editDialogCar = {
        data: {},
        display: true,
        type: 'create'
      }

      this.editDialogCar.saveFunction = async () => {
        try {
          this.carsTableButtonLoader = true

          const body = {
            brand: this.editDialogCar.data.brand,
            licensePlate: this.editDialogCar.data.licensePlate,
            model: this.editDialogCar.data.model,
            transmissionType: this.editDialogCar.data.transmissionType,
            passenger: this.$route.params.id
          }

          await this.createCar(body)

          const carList = await this.fetchCars()

          this.carList = carList
          this.carsTableButtonLoader = false
          this.editDialogCar.display = false

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Car is created'
          })
        } catch (error) {
          this.carsTableButtonLoader = false
        }
      }
    },
    openEditDialogCar (car) {
      this.editDialogCar = {
        data: { ...car },
        display: true,
        type: 'edit'
      }

      this.editDialogCar.saveFunction = async () => {
        try {
          this.carsTableButtonLoader = true

          await this.updateCar(car.id, {
            brand: this.editDialogCar.data.brand,
            licensePlate: this.editDialogCar.data.licensePlate,
            model: this.editDialogCar.data.model,
            transmissionType: this.editDialogCar.data.transmissionType
          })

          const carList = await this.fetchCars()
          this.carList = carList
          this.carsTableButtonLoader = false
          this.editDialogCar.display = false

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Car is updated'
          })
        } catch (error) {
          this.carsTableButtonLoader = false
        }
      }
    },
    createCar (bodyData) {
      return new Promise((resolve, reject) => {
        this.request('POST', `/cars`, { data: bodyData }, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    updateCar (id, bodyData) {
      return new Promise((resolve, reject) => {
        this.request('PATCH', `/cars/${id}`, { data: bodyData }, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    fetchCars () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/cars`, { params: { passenger: this.$route.params.id } }, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    fetchPaymentConditions () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/exact-online/payment-conditions`, {}, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    fetchPassengerRides (params = {}) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/rides`, { params: { passenger: this.$route.params.id, pageSize: 500, ...params } }, ({ data }) => {
          resolve(data.data)
        }, null, reject)
      })
    },
    fetchUser (userId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/users/${userId}`, {}, ({ data }) => {
          resolve({
            id: data.id,
            active: data.active,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone ? data.phone.number : '',
            country: data.country,
            language: data.language,
            roles: data.roles,
            brands: data.brands,
            plainPassword: data.plainPassword
          })
        }, null, reject)
      })
    },
    fetchRide (id) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/rides/${id}`, {}, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    fetchDiscounts () {
      return new Promise((resolve, reject) => {
        this.request('GET', `/passengers/${this.$route.params.id}/discount/driver-type`, {}, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    async getDiscounts () {
      [this.discounts] = await Promise.all([this.fetchDiscounts()])
    },
    fetchDriverTypes () {
      return new Promise((resolve, reject) => {
        this.request('GET', '/driver-types', {}, ({ data }) => {
          resolve(data.data)
        }, null, reject)
      })
    },
    onSelectMandate (value) {
      this.paymentSelectedMandate = value
    },
    async pay () {
      this.createPaymentLoader = true

      try {
        await this.createPayment({
          ride: this.paymentSelectedRide ? parseInt(this.paymentSelectedRide) : null,
          mandate: parseInt(this.paymentSelectedMandate),
          amount: this.paymentAmount,
          description: this.paymentDescription
        })

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Payment is created'
        })

        const ridePayments = await this.getPayments({ params: { user: this.user.id } })

        this.ridePayments = ridePayments

        this.createPaymentLoader = false
      } catch (error) {
        this.createPaymentLoader = false
      }
    },
    openDeleteDialogCar (carId) {
      this.deleteDialogConfirmationCar = () => {
        this.carsTableButtonLoader = true

        this.request('DELETE', `/cars/${carId}`, {}, async ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'warning',
            text: 'Car is deleted'
          })

          try {
            const carList = await this.fetchCars()

            this.carList = carList
            this.carsTableButtonLoader = false
          } catch (e) {
            throw new Error(e)
          }

          this.carsTableButtonLoader = false
          this.$refs.deleteCarConfrimationDialog.close()
        })
      }

      this.$refs.deleteCarConfrimationDialog.openDialog()
    },
    requestData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.request('GET', `/passengers/${this.$route.params.id}`, {}, async ({ data }) => {
          this.oldAddressBusiness = data.addressBusiness
          this.oldAddressPrivate = data.addressPrivate

          this.userData = data

          this.userData.addressBusiness = this.parseAddress(this.userData.addressBusiness)
          this.userData.addressPrivate = this.parseAddress(this.userData.addressPrivate)
          this.userData.defaultDriverType = this.userData.defaultDriverType && this.userData.defaultDriverType.id

          try {
            const user = await this.fetchUser(data.user.id)

            this.user = user
            this.loading = false
            this.initUser = lodash.cloneDeep(user)
            this.initUserData = lodash.cloneDeep(this.userData)
            resolve(user)
          } catch (err) {
            this.loading = false
            reject(err)
            throw new Error(err)
          }
        }, null, () => { this.loading = false })
      })
    },
    async saveFunction () {
      const bodyData = {
        description: this.userData.description,
        insure: this.userData.insure,
        options: this.userData.options,
        brands: this.userData.brands.map(brand => brand.id || brand),
        payOnInvoice: this.userData.payOnInvoice,
        invoicingType: this.userData.invoicingType,
        invoiceEmail: this.userData.invoiceEmail,
        useInvoiceEmailAsDefault: this.userData.useInvoiceEmailAsDefault,
        sepa: this.userData.sepa,
        poNumber: this.userData.poNumber,
        companyName: this.userData.companyName,
        business: this.userData.business,
        travelKmTariff: this.userData.travelKmTariff,
        defaultDriverType: this.userData.defaultDriverType || null,
        secondEmail: this.userData.secondEmail,
        loyaltyPoints: this.userData.loyaltyPoints,
        paymentConditions: {
          driveme: this.userData.paymentConditions.driveme.id,
          edrivers: this.userData.paymentConditions.edrivers.id,
          rentabob: this.userData.paymentConditions.rentabob.id
        }
      }

      const saveAddress = (invoiceType) => {
        const body = {
          data: {
            ...bodyData,
            invoiceType,
            address: invoiceType === 'business' ? this.unParseAddress(this.userData.addressBusiness) : this.unParseAddress(this.userData.addressPrivate)
          }
        }

        return this.request('PATCH', `/passengers/${this.userData.id}`, body)
      }

      if (this.unParseAddress(this.userData.addressPrivate) !== this.oldAddressPrivate) {
        await saveAddress(this.userData.invoiceType === 'private' ? 'business' : 'private')
        await saveAddress(this.userData.invoiceType === 'private' ? 'private' : 'business')
      } else if (this.unParseAddress(this.userData.addressBusiness) !== this.oldAddressBusiness) {
        await saveAddress(this.userData.invoiceType === 'private' ? 'business' : 'private')
        await saveAddress(this.userData.invoiceType === 'private' ? 'private' : 'business')
      } else {
        const body = {
          data: {
            ...bodyData,
            invoiceType: this.userData.invoiceType
          }
        }

        console.log(body, 'body')

        await this.request('PATCH', `/passengers/${this.userData.id}`, body)
      }

      let bodyUser = {
        data: {
          'email': this.user.email,
          'name': this.user.firstName + ' ' + this.user.lastName,
          'phone': this.user.phone,
          'country': this.user.country,
          'language': this.user.language,
          'brands': this.userData.brands.map(brand => brand.id || brand),
        }
      }

      await this.request('PATCH', `/users/${this.user.id}`, bodyUser)
      this.initUser = lodash.cloneDeep(this.user)
      this.initUserData = lodash.cloneDeep(this.userData)
      this.showPasswordField = false

      // this.$notify({
      //   group: 'topRight',
      //   type: 'success',
      //   text: 'Passagier is gewijzigd'
      // })

      // this.$router.push('/tables/passengers')
    },
    sendWelcome () {
      let body = {
        data: {
          passenger: this.userData.id
        }
      }

      this.request('POST', '/emailing/passenger-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    },
    openEditNoteDialog (note) {
      this.noteDialog = true
      this.editNoteObject = note
    },
    editNote () {
      const data = {
        data: {
          text: this.editNoteObject.text
        }
      }

      this.request('PATCH', `/notes/${this.editNoteObject.id}`, data, () => {
        this.noteDialog = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'The note has been updated'
        })
      })
    },
    openRemoveNoteDialog (id) {
      this.removeNoteDialog = true
      this.removeNoteId = id
    },
    cancelRemoveNoteDialog () {
      if (confirm('Are you sure you want to close?')) {
        this.removeNoteId = null
        this.removeNoteDialog = false
      }
    },
    deleteNote: function () {
      this.request('DELETE', `/notes/${this.removeNoteId}`, {}, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'The note has been deleted'
        })
        this.driverNotes = this.driverNotes.filter(item => item.id !== this.removeNoteId)
        this.getDriverNotes(this.$route.params.id, this.driverNotePerPage)
        this.removeNoteId = null
        this.removeNoteDialog = false
      })
    },
    getDriverNotes (id, limit, page = 1, sortDir = 'desc') {
      this.request('GET', `/notes?passenger=${id}&page=${page}&pageSize=${limit}&sort=createdAt&sortDir=${sortDir}`, {}, ({ data }) => {
        this.driverNoteTotalPage = Math.ceil(data.total / this.driverNotePerPage)
        this.driverNotes = data.data
      }, (loader) => { this.loadingNote = loader })
    },
    loadNotes () {
      this.getDriverNotes(this.$route.params.id, this.driverNotePerPage, this.driverNotePage, this.sortDirection)
    },
    async loadCombinedInvoices () {
      try {
        this.loading = true

        const { data: { data } } = await this.getCombinedInvoices({
          brands: this.userData.brands.map(brand => brand.id).join(','),
          passenger: this.userData.id,
          sentAt: this.isSentInvoice ? 1 : 0,
          synced: this.isSyncInvoice ? 1 : 0,
          draft: this.draftFilter
        })
        this.combinedInvoices = data.map((item) => ({ ...item, invoiceEditDialog: false, invoicedAt: this.moment.utc(item.invoicedAt).format('YYYY-MM-DDTHH:mm') }))
      } catch (error) {

      }

      this.loading = false
    },
    async loadCustomInvoices () {
      try {
        this.loading = true

        const { data: { data } } = await this.getCustomInvoices({
          passenger: this.userData.id,
          sentAt: this.isSentInvoice ? 1 : 0,
          synced: this.isSyncInvoice ? 1 : 0
        })

        this.customInvoices = data
      } catch (error) {

      }

      this.loading = false
    },
    saveNote () {
      let body = {
        data: {
          adminNote: this.userData.adminNote
        }
      }

      this.request('PATCH', `/passengers/${this.userData.id}`, body, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Admin has left a note to the customer'
        })
      })
    },
    addNote () {
      const body = {
        data: {
          text: this.newNoteObject.text,
          passenger: this.userData.id
        }
      }

      this.request('POST', '/notes', body, () => {
        this.loadNotes()
        this.newNoteDialog = false
        this.newNoteObject = {}
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Admin has left a note to the customer'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.line-border {
  border-bottom: 2px solid #303030;
}

.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}

.size-18 {
  font-size: 18px;
}

.add-car {
  margin-left: auto;
}

.field-wrapper {
  border: 2px solid rgba(0,0,0,0.54);
  border-radius: 4px;
  padding: 12px;
  margin: 0 0 15px 0;

  .custom-label {
    font-size: 1.1rem;
  }
}

.driver-note-wrapper {
  .custom-label {
    font-size: 12px;
  }

  .driver-note-holder {
    border-radius: 5px;
    padding: 10px;
    background-color: #F1F1F1;
    margin: 0 0 10px 0;

    .note-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      font-size: 12px;
    }
  }
}

.note-dialog {
  background-color: white;
  padding: 20px;
}

.note-actions {
  display: flex;

  .v-btn {
    color: white;
    width: 100%;
    flex: 1;
  }
}

.pagination-holder {
  display: flex;
  justify-content: center;
}

.sort-direction {
  width: 160px;
  margin: 0 0 0 auto;
}

  .loader-holder {
    min-height: 300px;
  }

  .btn-add-note {
    margin: 15px 0 0 0;
  }
</style>

<style lang="scss">
  .client-header {
    color: rgb(0, 175, 131) !important;
  }

  .secretary-header {
    color: rgb(215, 129, 0) !important;
  }

  .row-link {
    color: inherit;
  }
</style>
